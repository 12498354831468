.contests-title-container {
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
}

.contests-title {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.5rem;
  color: #262626;
}

@media only screen and (max-width: 600px) {
  .contests-title {
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
}

.contests-icon {
  cursor: pointer;
  height: fit-content;
  margin: auto;
}

.contests-list-view-icon {
  margin-left: 1rem;
}

.contests-wrapper-container {
  display: flex;
}

.contests-filters-container {
  display: none;
  margin: 1.5rem 1.5rem 0 0;
}

.contests-contests-container {
  margin-top: 1.375rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(13.625rem, 1fr));
  grid-row-gap: 1.75rem;
  grid-column-gap: 1.5rem;
  grid-auto-flow: dense;
  align-items: start;
}

.hidden-mobile {
  display: none;
}

.filters-button {
  display: block;
}

@media (min-device-width: 740px) {
  .filters-button {
    display: none;
  }
}

@media (min-device-width: 740px) {
  .hidden-mobile {
    display: block;
  }
}
@media (min-device-width: 740px) {
  .contests-filters-container {
    display: block;
  }
  .contests-contests-container {
    grid-template-columns: repeat(2, minmax(18.625rem, 1fr));
  }
}

@media (min-device-width: 960px) {
  .contests-contests-container {
    grid-template-columns: repeat(3, minmax(18.625rem, 1fr));
  }
}

.contests-pagination-container {
  float: right;
  padding: 1.625rem 0 0 0;
  margin: 0;
}

.contests-list-card {
  margin-top: 1.375rem;
  width: 58.85rem;
}

.contests-sort-container {
  display: flex;
  height: 2.625rem;
}

.contests-sort {
  margin-left: 1.75rem;
  width: 130px;
  max-width: 11.875rem;
}

@media (max-device-width: 740px) {
  .contests-sort {
    width: 6.875rem;
  }
  .contests-pagination-container {
    float: none;
    justify-content: center;
    padding: 1.625rem 0 0 0;
  }
  .contests-title-container {
    margin-bottom: 20px;
  }
}

.contests-no-contests {
  margin-top: 10rem;
  margin-left: 15rem;
  font-size: 1.125rem;
  color: #000000;
}
