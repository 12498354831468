.our-clients-title {
  text-align: center;
}

.our-clients-clients {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.our-clients-client {
  background: #ffffff;
  box-shadow: 0px 0px 1.25rem rgba(77, 108, 217, 0.16);
  border-radius: 1rem;
  height: 5rem;
  position: relative;
  cursor: pointer;
  margin-top: 2.5rem;
  margin-right: 1.5rem;
  flex-grow: 1;
  max-width: 33%;
  min-width: 17.5rem;
}

.our-clients-client-logo {
  object-fit: contain;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 90%;
  max-height: 80%;
}

@media only screen and (max-width: 720px) {
  .our-clients-client:nth-child(6n + 4) {
    margin-left: 0;
  }
  .our-clients-title {
    position: relative;
    left: 10rem;
    margin-bottom: 2rem;
  }

  .our-clients-client {
    position: relative !important;
    left: -3.5rem !important;
    top: 1rem;
  }
}
