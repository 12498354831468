@import url("./themes/theme.css");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito", sans-serif;
  color: var(--primary-text-color);
  background: #e5e5e5;
  overflow-x: hidden;
  overflow-y: scroll;
}

.wrapper {
  max-width: 85.375rem;
  margin: 0 auto;
}

.main {
  max-width: 100%;
  width: 100%;
  background: #f6f6f6;
  min-height: 50rem;
}

/* Remove default margins */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

/* ::-webkit-scrollbar {
  width: 0.25rem;
}

::-webkit-scrollbar-track {
  margin-bottom: 18rem;
}

::-webkit-scrollbar-thumb {
  background: #aab0cb;
  border-radius: 0.5rem;
} */

.loading {
  height: 30rem;
  margin: 15rem 50% 15rem 50%;
}

.MuiTooltip-tooltip {
  font-weight: bold !important;
}

.MuiSlider-thumb:last-child {
  margin-left: -1rem;
}

a {
  text-decoration: none;
}

.MuiMenu-paper .MuiList-root {
  display: table-caption !important;
}

fieldset {
  border: none;
}

.carousel .control-dots .dot {
  height: 0.75rem !important;
  width: 0.75rem !important;
  background: #e8eafa !important;
  border: 0.0625rem solid black !important;
}

.MuiList-root {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.MuiDialog-paper {
  width: -webkit-fill-available !important;
  width: -moz-available !important;
}

.MuiTableRow-root {
  height: auto !important;
}

.MuiTableCell-body {
  word-break: break-word;
}
