.login-wrapper {
  width: 65.625rem;
  height: 42.25rem;
}

.login-container {
  display: flex;
}

.login-welcome {
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.5rem;
  text-align: center;
  color: #262626;
  margin-top: 2.5rem;
}

.login-sign-in-text {
  font-size: 1.0625rem;
  line-height: 1.4375rem;
  margin-top: 0.5rem;
  text-align: center;
  color: #262626;
}

.login-form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login-input-label {
  margin-top: 2.5rem;
  margin-bottom: 0.25rem;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.125rem;
  color: #626880;
  text-align: left;
  width: 18.75rem;
}

.login-error-label,
.otp-error-label {
  margin-top: 0.2rem;
  color: #ff3b2d;
  font-weight: 700;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  text-align: left;
  width: 18.75rem;
}

.otp-error-label {
  text-align: center;
  height: 2.25rem;
}

.otp-timer {
  color: #ff3b2d;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.125rem;
}

.login-input {
  margin-top: 0.2rem;
  width: 18.75rem;
  height: 2.625rem;
  border-width: 0.0938rem;
  border-radius: 0.75rem !important;
}

.login-button,
.sendOtp-button {
  height: 2.625rem;
  width: 18.75rem;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
  border-radius: 1rem !important;
  margin-top: 1.5rem !important;
  background-color: #4d6cd9 !important;
  color: #ffffff !important;
}

.Mui-disabled.sendOtp-button {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.Mui-disabled.login-button {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.login-button-password {
  @extend .login-button;
}

.Mui-disabled.login-button-password {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.resend-otp-label {
  position: absolute;
  bottom: 14rem;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.125rem;
  color: var(--primary-button-background);
}

.bottom-signup-container {
  text-align: center;
  display: flex;
}

.bottom-signup-container a {
  text-decoration: none;
}

.bottom-signup-label {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #aab0cb;
  margin-top: 1.5rem;
}

.bottom-signup-label-link,
.enter-password,
.forgot-password {
  left: 1.3125rem;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #aab0cb;
  color: var(--primary-button-background);
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin-top: 1.5rem;
}

.enter-password {
  margin-top: 1rem;
}

.enter-password:disabled {
  color: #999;
}

.login-copyright {
  text-align: center;
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: #aab0cb;
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.login-loading {
  margin: 2rem auto 0 auto;
  text-align: center;
}

.back-button {
  position: absolute;
  top: 1rem;
  left: 52%;
  color: var(--primary-button-background);
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.enter-otp-label {
  font-size: 1.375rem;
  font-weight: 700;
  color: #30374c;
  text-align: center;
  margin-top: 4rem;
}

.enter-password-label {
  @extend .enter-otp-label;
}

.otp-info {
  color: #626880;
  font-size: 1rem;
  margin: 2rem 0rem;
}

.otpInputContainer,
.otpInputContainer-error {
  border-radius: 0.75rem;
  width: 2.625rem !important;
  margin: 1rem;
  height: 2.625rem;
  border: 0.125rem solid #aab0cb;
  font-size: 1rem;
}

.otpInputContainer-error {
  border: 0.125rem solid #ff3b2d;
}

.arrow {
  border: 0.125rem solid #4d6cd9;
  border-width: 0 0.125rem 0.125rem 0;
  display: inline-block;
  padding: 0.25rem;
  cursor: pointer;
}

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.login-copyright {
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
}
