.signup-page-wrapper {
  background: #4d6cd9;
  height: 100vh;
}

.signup-page-container {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
