.signup-wrapper {
  width: 65.625rem;
  height: 42.25rem;
}

.signup-container {
  display: flex;
}

.signup-form-container {
  position: relative;
  text-align: center;
}

.signup-welcome {
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.5rem;
  text-align: center;
  color: #262626;
}

.signup-sign-in-text {
  font-size: 1.0625rem;
  line-height: 1.4375rem;
  margin-top: 0.5625rem;
  text-align: center;
  color: #262626;
}

.signup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.signup-input-label {
  margin-top: 3rem;
  margin-bottom: 0.25rem;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.125rem;
  color: #626880;
  text-align: left;
  width: 18.75rem;
}

.signup-error-label,
.otp-error-label {
  margin-top: 0.2rem;
  color: #ff3b2d;
  font-weight: 700;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  text-align: left;
  width: 18.75rem;
  height: 2.25rem;
}

.otp-timer {
  color: #ff3b2d;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.125rem;
}

.signup-input,
.password-input {
  margin-top: 0.2rem;
  width: 18.75rem;
  height: 2.625rem;
  border-width: 0.0938rem;
  padding-left: 1rem;
  font-size: 1rem;
  border-radius: 0.75rem !important;
}

.password-input {
  margin-bottom: 0.5rem;
}

.signup-button,
.sendOtp-button,
.create-password-proceed {
  height: 2.625rem;
  width: 18.75rem;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
  border-radius: 1rem !important;
  background-color: var(--primary-button-background);
  color: #fff;
  border-width: 0rem;
  margin-top: 1.5rem !important;
}

.resend-otp-label {
  position: absolute;
  bottom: 14rem;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.125rem;
  color: var(--primary-button-background);
}

.resend-otp-label-active {
  color: var(--primary-button-background);
  cursor: pointer;
}

.resend-otp-label-inactive {
  color: #999999;
}

.bottom-signin-container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4rem;
}

.bottom-signin-container a {
  text-decoration: none;
}

.bottom-signin-label {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #aab0cb;
  margin-top: 1rem;
}

.bottom-signin-label-link,
.skip-now {
  left: 1.3125rem;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.125rem;
  color: var(--primary-button-background);
}

.skip-now {
  margin: 2rem;
  cursor: pointer;
  font-size: 1rem;
}

.signup-copyright {
  text-align: center;
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: #aab0cb;
  margin-top: 1.5rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2rem;
}

.signup-loading {
  margin: 2rem auto 0 auto;
  text-align: center;
}

.back-button {
  position: absolute;
  top: 1rem;
  left: 52%;
  color: var(--primary-button-background);
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.enter-otp-label {
  font-size: 1.375rem;
  font-weight: 700;
  color: #30374c;
  text-align: center;
  margin-top: 4rem;
}

.otp-info {
  color: #626880;
  font-size: 1rem;
  margin: 2rem 0rem;
}

.otpInputContainer,
.otpInputContainer-error {
  border-radius: 0.75rem;
  width: 2.625rem !important;
  margin: 1rem;
  height: 2.625rem;
  border: 0.125rem solid #aab0cb;
  font-size: 1rem;
}

.otpInputContainer-error {
  border: 0.125rem solid #ff3b2d;
}

.arrow {
  border: 0.125rem solid #4d6cd9;
  border-width: 0 0.125rem 0.125rem 0;
  display: inline-block;
  padding: 0.25rem;
  cursor: pointer;
}

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.create-password {
  font-size: 1.375rem;
  font-weight: 700;
  color: #30374c;
  margin-bottom: 3rem;
}

.is-invalid,
.is-invalid:focus {
  color: red;
  border: 0.0625rem solid red !important;
}

.invalid-feedback {
  color: red;
  font-size: 1rem;
  margin-bottom: 2rem;
}

.signup-form-container {
  width: -webkit-fill-available;
  width: -moz-available;
  margin-top: 2.5rem;
}

.horizontal-divider {
  color: #ced4f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 1.5rem 0rem;
  font-size: 1rem;
  width: 12.5rem;
}

.divider {
  color: #ced4f0;
  border: 0.0625rem solid #ced4f0;
  width: 4.0625rem;
  height: 0rem;
}

.social-buttons-container {
  max-width: 18.75rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-button {
  width: 18.75rem;
  height: 3rem;
  border: 0.125rem solid #dfe5ff !important;
  color: #30374c !important;
  font-size: 1rem !important;
  margin-bottom: 1.5rem !important;
  border-radius: 1rem !important;
  text-transform: capitalize !important;
}

.social-button:hover {
  background-color: white;
  color: #30374c;
}
